<template>
  <div
    class="WalkthroughView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.shared.steps.walkthrough.title')"
      :stepper-steps="stepperSteps"
      :active-stepper-step-id="currentStep.id"
    />

    <!-- Modal -->
    <ModalBase
      :is-open="isModalOpen"
      width="100%"
      height="100%"
      max-width="100%"
      max-height="100%"
      @close="isModalOpen = false">
      <div
        ref="calendly"
        style="height: 90%;">
      </div>

      <footer
        class="__modal-footer">
        <div
          class="__label">
          {{ computedScheduleLabel }}
        </div>
        <ButtonBase
          class="__cta"
          theme="primary"
          :is-loading="isSubmitting"
          @click="handleSubmit">
          {{ $t('ctas.launchDomoNow') }}
        </ButtonBase>
      </footer>
    </ModalBase>

    <div class="container">
      <!-- Form -->
      <InputSelect
        class="InputSelect-theme-radio"
        v-show="!session.isWalkthroughScheduled.value"
        v-model="session.poc.value"
        :is-tiles="true"
        :is-multi-select="false"
        :options="$i18n.messages[$i18n.locale].poc"
        :is-invalid="hasSubmitted && !session.poc.isValidClientSide"
        :is-disabled="(isSubmitting || session.isWalkthroughScheduled.value)"
        @select="handleSelect">
        <template
          v-slot:tile="{ option, isSelected }">
          <div
            class="__tile"
            :class="{ isSelected }">
            <IconRadio
              class="__radio"
              size="24"
              color="#99CCEE"
              :is-checked="isSelected"
            />

            <div
              class="__label">
              <div
                v-html="option.label">
              </div>
            </div>
          </div>
        </template>
      </InputSelect>

      <!-- Auto Launching -->
      <div
        class="h4 text-center"
        v-show="session.isWalkthroughScheduled.value">
        {{ computedScheduleLabel }}
      </div>

      <!-- Error -->
      <ErrorTile
        class="mt-1"
        v-show="hasSubmitted && !isCurrentStepValidClientSide">
        {{ $t('shared.walkthroughIsRequired') }}
      </ErrorTile>

      <!-- Cta -->
      <div
        class="text-center mt-4">
        <ButtonBase
          class="uppercase"
          type="submit"
          :is-loading="isSubmitting"
          @click="handleSubmit">
          {{ $t('ctas.finishAndLaunchDomo') }}
        </ButtonBase>
      </div>
    </div>

  </div>
</template>


<script>
import useCalendly from '@/use/calendly';
import HeaderStep from '@/components/HeaderStep.vue';
import ModalBase from '@/components/ModalBase.vue';
import InputSelect from '@/components/InputSelect.vue';
import IconRadio from '@/components/IconRadio.vue';
import ErrorTile from '@/components/ErrorTile.vue';
import ButtonBase from '@/components/ButtonBase.vue';

const AUTOLAUNCH_COUNTDOWN_DURATION = 5; // <- seconds

export default {
  name: 'WalkthroughView',

  components: {
    HeaderStep,
    ModalBase,
    InputSelect,
    IconRadio,
    ErrorTile,
    ButtonBase,
  },

  inject: [
    'domo',
    'flow',
    'session',
    'currentStep',
    'stepperSteps',
    'isCurrentStepValidClientSide',
  ],

  setup() {
    const {
      initCalendly,
    } = useCalendly();

    return {
      initCalendly,
    };
  },

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      isModalOpen: false,
      autoLaunchTimer: AUTOLAUNCH_COUNTDOWN_DURATION,
    };
  },

  computed: {
    computedScheduleLabel() {
      if (this.isSubmitting) return `${this.$t('shared.launchingDomo')}...`;

      return this.session.isWalkthroughScheduled.value
        ? this.$t('shared.launchingInSeconds', { seconds: this.autoLaunchTimer })
        : this.$t('shared.wantToScheduleLater');
    },
  },

  mounted() {
    /*
     * @NOTE Calendly URL could be provided from translation files.
     * But, until start-free is officially localized we use the country
     * value to determine calendly url
     */
    this.initCalendly({
      // url: this.$i18n.messages[this.$i18n.locale].calendly,
      country: this.session.country.value,
      el: this.$refs.calendly,
      prefill: {
        name: `${this.session.firstName.value} ${this.session.lastName.value}`,
        email: this.session.email.value,
      },
      hooks: {
        eventScheduled: this.handleCalendlyEventScheduled,
      },
    });
  },

  methods: {
    handleSelect(option) {
      this.isModalOpen = option.value || false;
    },

    handleCalendlyEventScheduled() {
      this.session.isWalkthroughScheduled.value = true;
      this.startAutoLaunchTimer(AUTOLAUNCH_COUNTDOWN_DURATION);
    },

    startAutoLaunchTimer(duration) {
      this.autoLaunchTimer = duration;

      if (this.autoLaunchTimer <= 0) return this.handleSubmit();

      return setTimeout(() => this.startAutoLaunchTimer(duration - 1), 1000);
    },

    async handleSubmit() {
      this.hasSubmitted = true;

      if (this.isSubmitting || !this.isCurrentStepValidClientSide) return;

      this.isSubmitting = true;

      await this.currentStep.submitHandler({
        domo: this.domo,
        session: this.session,
        currentStep: this.currentStep,
      });
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.__modal-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  min-height: 80px;
  padding: 0 24px;
  align-items: center;
  justify-content: flex-end;
  background-color: $white;
  border-top: 1px solid rgba(26, 26, 26, 0.1);
  .__label {
    padding-right: 16px;
    line-height: $line-height-title;
  }
  .__cta {
    flex: 0 0 auto;
    white-space: nowrap;
    text-transform: uppercase;
  }
}
</style>
